@import "~@mdi/font/css/materialdesignicons.css";
@import "~leaflet/dist/leaflet.css";
@import "~react-leaflet-markercluster/dist/styles.min.css";
@import "~react-toastify/dist/ReactToastify.css";
@import "~react-image-lightbox/style.css";
@import "~react-toggle/style.css";
@import "~rc-slider/assets/index.css";
@import "libs/react-date-picker";
@import "libs/swal";

@import "theme/theme";

@import "overrides/buttons";
@import "overrides/cards";
@import "overrides/forms";
@import "overrides/left-menu";
@import "overrides/image";
@import "overrides/masonry";
@import "overrides/modal";
@import "overrides/navigation";
@import "overrides/scrollbars";
@import "overrides/structure-flex";
@import "overrides/utilities";

.ReactModal__Overlay {
  z-index: 4001 !important;
}

.flex-overlay {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.flex {
  flex: 1;
}

.modal.fade {
  -webkit-transition: none;
  -moz-transition: none;
  -ms-transition: none;
  -o-transition: none;
  transition: none;
}

.artifact-marker {
  $marker-size: 1.5rem;
  background-color: $info;
  width: $marker-size;
  height: $marker-size;
  display: block;
  left: -($marker-size/2)rem;
  top: -($marker-size/2)rem;
  position: relative;
  border-radius: $marker-size $marker-size 0;
  transform: rotate(45deg);
  border: 1px solid #FFFFFF;

  &.last {
    $marker-size: 2rem;
    width: $marker-size;
    height: $marker-size;
    left: -($marker-size/2)rem;
    top: -($marker-size/2)rem;
    border-radius: $marker-size $marker-size 0;
    background-color: $primary;
  }
}

.react-transform-component {
  width: unset !important;
  height: unset !important;

  .react-transform-element {
    width: unset !important;
    height: unset !important;
    justify-content: center;

    img {
      max-width:  100%;
      max-height: 100%;
      display: block;
    }
  }
}

.artifact-ribbon {
  //-webkit-clip-path: polygon(0 0,100% 0,100% 100%,0 100%,15% 50%);
  //clip-path: polygon(0 0,100% 0,100% 100%,0 100%,15% 50%);
  margin-right: -5px;
  margin-top: 6px;
  //padding-left: 25px;
  //padding-right: 12px;
  border-radius: 3px 0 0 3px;
  position: absolute;
  clear: both;
  padding: 5px 12px;
  top: 0;
  z-index: 1;
  right: 0;
  margin-bottom: 15px;
  box-shadow: 2px 5px 10px #323a4626;
  color: #fff;
  font-size: 13px;
  font-weight: 600;
}

.filters-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.29);
  z-index: 1002;
}

.filters-sidebar {
  display: flex;
  flex-direction: column;
  background: white;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 1003;
  overflow: hidden;
  width: 90%;

  @include media-breakpoint-up(lg) {
    width: 70%;
  }
}

.mobile-toggle-overlay {
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1101;

  padding: 20px;
}

.mobile-table-container {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1100;
}
